<template>
	<div id="huiyiDetails">
		<div class="tabs">
			<div class="meetingDetails">
				<h1 class="title">
					会议详情
					<p>
						会议状态：
						<span :class="meetDetail.meetingStatus" v-if="meetDetail.meetingStatus == 'WAIT'">待开始</span>
						<span :class="meetDetail.meetingStatus"
							v-else-if="meetDetail.meetingStatus == 'LIVING'">进行中</span>
						<span class="FINISG" v-else>已结束</span>
					</p>
				</h1>
				<el-row :gutter="20">
					<el-col :span="24">
						<p>所属项目</p>
						<div>{{meetDetail.projectName}}</div>
					</el-col>
					<el-col :span="12">
						<p>会议主题</p>
						<div>{{meetDetail.meetingTitle}}</div>
					</el-col>
					<el-col :span="12">
						<p>会议类型</p>
						<div>{{ meetDetail.meetingType == 'single'?"单场会":"系列会"}}</div>
					</el-col>
					<el-col :span="12">
						<p>会议日期</p>
						<div v-if="meetDetail.meetingStartTime">
							{{ $moment(meetDetail.meetingStartTime).format('LL')}}
						</div>
					</el-col>
					<el-col :span="12">
						<p>会议开始时间</p>
						<div v-if="meetDetail.meetingStartTime">
							{{ $moment(meetDetail.meetingStartTime).format('LT')}}
						</div>
					</el-col>
				</el-row>
				<h1 class="title">参会人员</h1>
				<el-row :gutter="20" class="users">
					<el-col :span="24" v-for="(item,i) in meetDetail.users" :key="i">
						<p>{{charactersList[i]}}</p>
						<div>
							<span v-for="(item2,k2) in item" :key='k2'>
								{{item2.fullName}}<i v-if="k2<item.length-1">， </i>
							</span>
						</div>
					</el-col>
				</el-row>
				<div class="options">
					<el-button type="primary" @click="back()" class="back">返回</el-button>
					<el-button type="primary" @click="enterMeeting()" class="enter"
						v-if="meetDetail.meetingStatus != 'FINISH' && meetDetail.isSign == 'YES'">进入会议</el-button>
					<el-button type="warning" class="sign" @click="toagreement(meetDetail)"
						v-if="meetDetail.isSign == 'NO'">签署劳务协议</el-button>
				</div>
				<p class="agreement" v-if="meetDetail.meetingStatus != 'ERROR' && meetDetail.isSign == 'YES'">
					<i class="el-icon-check"></i>
					劳务协议已签署
					<!-- <el-link type="primary" :underline="false" >
					</el-link> -->
					<a class="fire" :href="agreementAndInvoiceData.agreementPath">点击查看</a>
				</p>

			</div>
			<div class="laborProgress" label="劳务进度" name="2">
				<h1 class="title">
					劳务状态：
					<span :class="meetDetail.meetingStatus" v-if="meetDetail.meetingStatus == 'WAIT'">会议未开始</span>
					<template v-else>
						<span :class="meetDetail.orderStatus" v-if="meetDetail.orderStatus == 'FINISH'">已支付</span>
						<span :class="meetDetail.orderStatus" v-if="meetDetail.orderStatus == 'ERROR'">异常</span>
						<span :class="meetDetail.orderStatus" v-if="meetDetail.orderStatus == 'END'">已取消</span>
						<span :class="meetDetail.orderStatus" v-if="meetDetail.orderStatus == 'WAIT'">待支付</span>
					</template>
				</h1>
				<div class="user">
					<div class="avatar">
						<img src="../../../assets/imgs/yisheng.svg">
					</div>
					<div class="info">
						<div class="name">{{userInfo.fullName}}</div>
						<div class="stars">
							<img src="../../../assets/imgs/star.png" v-for="i in 5" :key="i">
						</div>
						<div class="status">
							<p>
								<img src="../../../assets/imgs/Layer.png">
								已完成平台备案
							</p>
						</div>
					</div>
				</div>
				<div class="card">
					<div class="preTax">税前劳务：<b>￥{{meetDetail.preTax}}</b></div>
					<!-- <div class="rate">劳务服务税点代扣：<b>{{meetDetail.rate}}%</b></div> -->
					<div class="rate">提示：税后金额仅供参考，实际税款请以税务机关核定为准。</div>
					<div class="afterTax">预计税后劳务：<b>￥{{meetDetail.afterTax}}</b></div>
				</div>
				<div class="feedback">
					<!-- <div class="agreement">
						<a v-if="agreementAndInvoiceData.agreementPath" :href="agreementAndInvoiceData.agreementPath"
							target="_blank">查看【{{meetDetail.meetingTitle}}】劳务协议</a>
						<span v-else>劳务协议未签署</span>
					</div> -->

					<div class="invoice">
						<p>发票</p>
						<div class="row">
							<a :href="agreementAndInvoiceData.invoicePath" v-if="agreementAndInvoiceData.invoicePath"
								target="_blank">查看【{{meetDetail.meetingTitle}}】发票</a>
							<span v-else>您的发票还未上传。</span>
							<el-upload class="upload" :action="`${baseUrl}/sign/live/doctor/uploadFile`"
								:show-file-list="false" :on-success="handleSuccess">
								<el-button size="mini" type="info"
									v-if="agreementAndInvoiceData.invoicePath">更换发票</el-button>
								<el-button size="mini" type="primary" v-else>上传发票</el-button>
							</el-upload>

						</div>
						<div class="row" style="">
							<a slot="tip" class="tip" style="font-size: 14px;"
								href="https://life-oasis.oss-cn-beijing.aliyuncs.com/video/%E8%8D%AF%E6%95%99%E5%8D%8F%E5%AD%97182%E5%8F%B7--%E5%85%B3%E4%BA%8E%E5%BC%80%E5%85%B7%E5%8A%B3%E5%8A%A1%E8%B4%B9%E5%A2%9E%E5%80%BC%E7%A8%8E%E5%8F%91%E7%A5%A8%E7%9A%84%E9%80%9A%E7%9F%A5.pdf"
								target="_blank">查看开票方式</a>
							<a href="https://etax.beijing.chinatax.gov.cn" class="link" target="_blank"
								style="font-size: 14px;">去开发票</a>
						</div>

					</div>

					<div class="type" v-if="meetDetail.orderStatus == 'WAIT'">暂无问题</div>
					<div class="type" v-else-if="meetDetail.orderStatus == 'SUCCESS'">劳务费用已支付</div>
					<div class="type err" v-else>
						支付失败：{{meetDetail.errorMessage}}
						<br>
						<el-button size="small" v-if="meetDetail.errorMessage == '身份、账号信息错误'" type="danger"
							@click="modifyInformation">现在去修改</el-button>
						<el-button size="small" v-if="meetDetail.errorMessage&&meetDetail.errorMessage != '身份、账号信息错误'"
							type="danger" @click="appealShow = true">我要申诉</el-button>
					</div>
					<!-- <div class="tip">提示：税后金额仅供参考，实际税款请以税务机关核定为准。</div> -->
					<el-dialog title="请输入申诉理由" :visible.sync="appealShow" v-if="appealShow" width="600px">
						<el-input type="textarea" :rows="4" placeholder="请输入申诉理由" v-model="appealVal">
						</el-input>
						<span slot="footer" class="dialog-footer">
							<el-button type="primary" @click="launchAComplaint">确 定</el-button>
						</span>
					</el-dialog>


				</div>
			</div>
		</div>
		<el-dialog title="参会签到" :visible.sync="joinDialog" v-if="joinDialog" width="400px" :center="true">
			<el-form class="join loginForm" :model="joinform" label-width="60px" label-position="top" :rules="rules"
				ref="ruleForm">
				<el-form-item label="姓名" prop="userName">
					<el-input v-model="joinform.userName" placeholder="请输入姓名" clearable></el-input>
				</el-form-item>
				<el-form-item label="手机号" prop="phoneNumber">
					<el-input v-model="joinform.phoneNumber" placeholder="请输入手机号" maxlength="11" clearable>
						<p slot="append" @click="sendCodeFun(joinform.phoneNumber)">{{btnText}}</p>
					</el-input>
				</el-form-item>
				<el-form-item label="验证码" prop="verifyCode">
					<el-input v-model="joinform.verifyCode" placeholder="请输入验证码" clearable> </el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="joinDialog = false">取消</el-button>
				<el-button type="primary" @click="signInAndEnterTheMeeting('ruleForm')">签到并进入会议</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		reapply,
		sendCode,
		signIn,
		getMeetDetil,
		roleList,
		getDoctorInfo,
		doctorGetMeetingUrl,
		doctorGetMeetingFile,
		getDoctorAgreement, // 获取劳务协议和发票
		submitMeetingInvoice // 上传发票
	} from '@/request/api.js';
	import baseUrl from "@/request/config.js"
	const validateMobile = (rule, value, callback) => {
		let valueReg = /^1[3-9]\d{9}$/;
		if (!valueReg.test(value)) {
			callback(new Error('请输入正确的手机号'));
		} else {
			callback();
		};
	};
	export default {
		name: 'huiyiDetails',
		components: {},
		data() {
			return {
				baseUrl: '',
				userInfo: '',
				meetingId: '',
				activeName: '1',
				meetDetail: '',
				videoActive: '',
				charactersList: '',
				joinDialog: false,
				joinform: {
					meetingId: '',
					signLiveMeetingId: '',
					userName: '',
					phoneNumber: '',
					verifyCode: ''
				},
				btnText: "获取验证码",
				second: 60,
				rules: {
					phoneNumber: [{
							required: true,
							message: '请输入手机号',
							trigger: 'blur'
						},
						{
							validator: validateMobile.bind(this),
							trigger: 'blur'
						}
					],
					userName: [{
						required: true,
						message: '请输入姓名',
						trigger: 'change'
					}],
					verifyCode: [{
						required: true,
						message: '请输入验证码',
						trigger: 'change'
					}]
				},
				appealShow: false,
				appealVal: '',
				agreementAndInvoiceData: '',
				invoiceList: [],

			}
		},
		created() {
			this.baseUrl = baseUrl;
			this.meetingId = this.$route.query.mId;
			this.joinform.signLiveMeetingId = this.$route.query.mId;
			this.getMeetDetails();
			this.getDoctorInfoFun();
			this.getDoctorAgreementFun();
		},
		mounted() {},
		methods: {

			getDoctorAgreementFun() {
				let data = {
					meetingId: this.meetingId
				}
				getDoctorAgreement(data).then(res => {
					if (res.code == 200) {
						console.log(res.data)
						this.agreementAndInvoiceData = res.data
						this.$forceUpdate();
					}
				})
			},

			handleSuccess(response, file, fileList) {
				let data = {};
				data.meetingId = this.meetingId;
				data.invoiceName = response;

				submitMeetingInvoice(data).then(res => {
					if (res.code == 200) {
						// console.log(res)
						this.getDoctorAgreementFun();
					}
				})
			},

			launchAComplaint() {
				if (this.appealVal) {
					reapply({
						id: this.meetDetail.orderId,
						reapplyMessage: this.appealVal
					}).then(res => {
						if (res.code == 200) {
							this.$message.success('提交成功');
							this.appealShow = false;
							this.getMeetDetails();
						} else {
							this.appealShow = false;
						};
					}).catch(err => {
						this.appealShow = false;
					});
				};
				/* this.$prompt('请输入申诉理由', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputPattern: /\S/,
					inputErrorMessage: '请输入申诉理由',
					inputPlaceholder: '请输入申诉理由'
				}).then(({
					val
				}) => {
					console.log(val)
				}) */
			},
			modifyInformation() {
				this.$router.push({
					name: 'changeInfo'
				});
			},
			// 获取验证码
			sendCodeFun(e) {
				let valueReg = /^1[3-9]\d{9}$/;
				if (!e) {
					this.$notify.error({
						title: '错误',
						message: '请输入手机号',
						position: 'bottom-right'
					});
				} else if (!valueReg.test(e)) {
					this.$notify.error({
						title: '错误',
						message: '请输正确的入手机号',
						position: 'bottom-right'
					});
				} else {
					sendCode(this.joinform.phoneNumber).then(res => {
						this.getCode();
						// console.log(res);
					}).catch(err => {
						console.log(err);
					});
				};
			},
			getCode() {
				this.btnText = `${this.second}秒后再次获取`;
				this.second--;
				if (this.second < 0) {
					this.btnText = '获取验证码';
					this.second = 60;
				} else {
					setTimeout(() => {
						this.getCode();
					}, 1000);
				};
			},
			getDoctorInfoFun() {
				getDoctorInfo().then(res => {
					if (res.code == 200) {
						this.userInfo = res.data;
						this.joinform.userName = res.data.fullName;
						this.joinform.phoneNumber = res.data.phoneNumber;
					};
				});
			},
			// 获取会议详情
			getMeetDetails() {
				let data = {
					meetingId: this.meetingId
				};
				getMeetDetil(data).then(res => {
					if (res.code == 200) {
						this.joinform.meetingId = res.data.meetingId;
						this.meetDetail = res.data;
						this.videoActive = res.data.videoPath[0];
						if ((res.data.meetingStatus == 'FINISH' || res.data.meetingStatus == 'SUCCESS') && res.data
							.isSign == 'YES') {
							this.activeName = '2';
						};
					};
				});
				doctorGetMeetingFile(data).then(res => {
					console.log(res)
				})
				roleList(data).then(res => {
					if (res.code == 200) {
						let charactersList = {};
						res.data.map(item => {
							charactersList[item.id] = item.roleName
						});
						this.charactersList = charactersList;
					};
				});
			},
			handleClick(e) {
				console.log(e.index);
			},
			// 切换视频
			videoChange(item) {
				this.videoActive = item;
			},
			// 进入会议
			enterMeeting() {
				let data = {
					meetingId: this.meetingId
				};
				if (this.meetDetail.join) {
					doctorGetMeetingUrl(data).then(res => {
						if (res.code == 200) {
							// console.log(res.data)
							window.open(res.data);
						};
					});
				} else {
					this.joinDialog = true;
				};
			},
			// 签到并进入会议
			signInAndEnterTheMeeting(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						signIn(this.joinform).then(res => {
							if (res.code == 200) {
								this.enterMeeting();
							};
						});
					} else {
						console.log('error submit!!');
						return false;
					};
				});
			},
			// 去签署协议
			toagreement(item) {
				this.$router.push({
					name: 'agreement',
					query: {
						mId: item.id
					}
				});
			},
			back() {
				this.$router.go(-1);
			}
		}
	}
</script>

<style lang="scss" scoped>
	#huiyiDetails {
		padding: 20px;
		height: 100%;

		.tabs {
			width: 100%;
			display: flex;
			flex-direction: row;
			height: 100%;

			.title {
				font-size: 20px;
				width: 100%;
				margin-bottom: 20px;
				display: flex;
				align-items: center;

				>p {
					flex: 1;
					display: flex;
					justify-content: flex-end;
					font-size: 16px;
				}

				// FINISH 	已支付
				.FINISH {
					color: #67C23A;
				}

				// ERROR 	已驳回
				.ERROR {
					color: #F56C6C;
				}

				// CANCEL 	已取消
				.CANCEL {
					color: #909399;
				}

				// WAIT 	待支付
				// LIVING 	进行中
				.WAIT,
				.LIVING {
					color: #409EFF;
				}
			}
		}

		.meetingDetails {
			flex: 2;
			padding: 30px 20px;
			border-radius: 20px;
			box-shadow: 0px 2px 23px rgba(0, 0, 0, .1);

			.users {
				i {
					font-style: normal;
				}
			}

			.el-row {
				margin-bottom: 20px;
			}

			.el-col {
				overflow: hidden;
				margin-bottom: 10px;

				>p {
					font-size: 14px;
					color: #112950;
				}

				>div {
					background-color: #f5f5f5;
					margin: 10px 0 0;
					font-size: 15px;
					color: #90A3BF;
					text-align: justify;
					padding: 14px 20px;
					border-radius: 10px;
					min-height: 50px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}

			.options {
				display: flex;
				justify-content: center;

				.el-button {
					width: 200px;
					height: 50px;
				}

				.sign {
					background-color: #D43030;
				}
			}

			.agreement {
				width: 100%;
				margin-top: 20px;
				color: #67C23A;
				font-size: 14px;
				font-weight: bold;
				display: flex;
				align-items: center;
				justify-content: center;

				.el-icon-check {
					margin-right: 4px;
				}

				.fire {
					color: #67C23A;
					margin-left: 10px;
				}
			}
		}

		.laborProgress {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 30px 20px;
			border-radius: 20px;
			margin: 0 0 0 20px;
			box-shadow: 0px 2px 23px rgba(0, 0, 0, .1);

			.user {
				display: flex;
				align-items: center;
				width: 100%;
				margin-bottom: 20px;
				padding-bottom: 30px;
				border-bottom: 2px solid #eee;

				.avatar {
					background-color: #0065FF;
					width: 130px;
					height: 100px;
					padding: 16px;
					border-radius: 10px;
					display: flex;
					justify-content: center;
					align-items: center;

					img {
						height: 100%;
					}
				}

				.info {
					height: 100%;
					flex: 1;
					padding-left: 40px;
					display: flex;
					flex-direction: column;
					justify-content: space-around;

					.name {
						font-size: 20px;
						color: #333;
					}

					.stars {
						display: flex;

						img {
							height: 14px;
							margin-right: 2px;
						}
					}

					.status {
						display: flex;
						align-items: center;
						font-size: 14px;
						color: #216ECC;

						img {
							height: 16px;
							margin-right: 0px;

						}
					}
				}
			}

			.card {
				width: 100%;
				padding: 0;
				display: flex;
				align-items: center;
				flex-direction: column;
				border-bottom: 2px solid #eee;

				>div {
					width: 100%;
					display: flex;
					justify-content: space-between;
					padding: 20px 10px;
					font-size: 17px;
					color: #90A3BF;

					b {
						display: flex;
						color: #1A1F2C;
						font-weight: normal;
						align-items: center;
					}
				}

				.preTax {}

				.rate {
					background-color: #F6F7F9;
					border-radius: 10px;
				}

				.afterTax {
					color: #1A1F2C;
					font-size: 24px;
					align-items: center;

					b {
						font-size: 44px;
					}
				}
			}

			.feedback {
				margin-top: 30px;
				width: 100%;
				font-size: 16px;

				.type {
					text-align: center;
					background-color: #f5f5f5;
					padding: 20px;
					border-radius: 20px;
					color: #90A3BF;
					margin-bottom: 20px;
				}

				.err {
					color: #999;

					.el-button {
						margin-top: 10px;
					}
				}

				.tip {
					font-size: 14px;
					text-align: center;
					margin: 20px 0;
					color: #409EFF;
				}

				.link {
					font-size: 14px;
					text-align: center;
					margin-left: 10px;
					color: #409EFF;
				}
			}




			.invoice {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				margin-bottom: 20px;

				>p {
					font-size: 20px;
					margin-bottom: 20px;
				}

				.row {
					flex-wrap: wrap;
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;
					margin-bottom: 10px;

					>a {
						margin: 0 10px 0 0;
						font-size: 17px;
						color: #409EFF;
					}

					// span {
					// 	font-size: 17px;
					// 	color: #90A3BF;
					// }

					.upload {
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: flex-start;

						.tip {
							font-size: 14px;
							// color: #999;
							margin: 0 !important;
							margin-left: 10px;
						}

						.link {
							margin: 0;
						}
					}
				}




			}
		}

		.conferenceReplay {
			.liveReplay {
				display: flex;
				margin: 30px auto;
				width: 90%;
				height: 500px;
				box-shadow: 0 0 20px rgba(0, 0, 0, .1);
				background-color: #000;
			}

			ul {
				margin: 30px auto;
				width: 90%;

				li {}
			}
		}
	}
</style>